import logo from './logo.svg';
import './App.css';
import AppHeader from './components/AppHeader';
import ProductList from './components/ProductList';
import {useEffect, useRef, useState} from 'react';
import ProductDetail from './components/ProductDetail'; 
import Messenger from './components/Messenger';
import StartAssistant from './components/StartAssistant';
function App() {
  const detailRef = useRef();
  const assistantRef = useRef();
  

  const [products, setProducts] = useState([
    {id:'1', name:'鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权', image:'/logo512.png', oldPrice:1234, price:1234, desc:'鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础'},
    {id:'2', name:'鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权', image:'/logo512.png', oldPrice:1234, price:1234, desc:'鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础'},
    {id:'3', name:'鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权', image:'/logo512.png', oldPrice:1234, price:1234, desc:'鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础'}
  ]);

  const showProduct = (item) =>{ 
    detailRef.current.show(item);
    window.__sna.visit({ 
              page:{key:item.id, name:item.name, desc:item.desc}, 
            });
  }
  
  window.showProduct = showProduct;


  const showAssistant = (item) =>{ 
    assistantRef.current.show(item);
    window.__sna.visit({ 
              page:{key:'assistant', name:'Shopping Assistant', desc:'AI Assistant'}, 
            });
  } 
  window.showAssistant = showAssistant;


  let selectedProduct = products[0];

 
  window.__sna__initparams =  null;
  window.__sna  =  window.__sna ||  {  
    init:function(params){
      if(window.__sna.loaded){
        window.__sna.init(params);
      }
      else{
        window.__sna__initparams = params;
      }
    }
  };
  


  useEffect(()=>{
    if(selectedProduct){
      if(window.__sna.init)
        window.__sna.init({page:{key:selectedProduct.id, name:selectedProduct.name, desc:selectedProduct.desc}});
    }
    else{
      if(window.__sna.init)
        window.__sna.init({page:{key:'home', name:'Home Page'}});
    }  
  }, [])

  return (
    <div className="app">
       <AppHeader />  
       <ProductList items={products}   />
       <ProductDetail  item={selectedProduct} open={selectedProduct?true:false} ref={detailRef} />
       <StartAssistant />
       <Messenger ref={assistantRef} />
    </div>
  );
}

export default App;
