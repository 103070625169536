 
import './Message.css';
import ProductItem from './ProductItem';

function Message(props) {
  let userImage = '';

  const getContentItem = () => {
    return <>
    {props.item.type=='text' &&  <div dangerouslySetInnerHTML={{__html:props.item.content}}></div>} 
    {props.item.type=='product' &&  <ProductItem item={props.item.content}  />} 
    {props.item.type=='image' && <img src={props.item.content} />} 
    </>
  }

  return (
    <div className={"message " + (props.item.from=='user'?' user ': ' ai ')}>
     
        <div className={"sender-image " } style={{backgroundImage:'url('+(props.item.from=='user'?userImage:'/ai.png')+')'}}></div>
        <div className="sender-name">{(props.item.from=='user'?'You':'ShopNow Ai')}</div>
        <div className="sender-desc">{props.item.time}</div>
        <div className="content">
         {getContentItem()}
        </div> 

    </div>
  );
}

export default Message;
