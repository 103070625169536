 
import ProductItem from './ProductItem';
import './ProductList.css';

function ProductList(props) {
  
  return (
    <div className="product-list">
      {props.items.map(i=> <ProductItem  key={i.id}  onClick={props.onClick} item={i} /> )}
    </div>
  );
}

export default ProductList;
