 
import './AppHeader.css';
import Icon from './Icon';

function AppHeader() {
  return (
    <div className="app-header">
       <div className="app-logo"></div>
       <div className="app-header-center"></div>
       <div className="app-icons">
          <Icon name="account_circle" clickable={true} />
          <Icon name="support_agent" clickable={true} />
          <div className="app-icon"></div>
       </div>
    </div>
  );
}

export default AppHeader;
