 
 
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'; 
import './StartAssistant.css';

const StartAssistant = forwardRef((props,ref)=> {

  

  return (
    
      <div className="start-assistant" onClick={window.showAssistant}>
        What are you looking for?  Click here to find!
      </div> 
  );
});

export default StartAssistant;
