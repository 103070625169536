 
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Icon from './Icon';
import './ProductDetail.css';

const ProductDetail = forwardRef((props,ref)=> {

  useImperativeHandle(ref, () => ({ 
    show, hide
  }));
    
  
  const [visible, setVisible] = useState(props.open);

  const [item, setItem] = useState(props.item);

  const scorolled = (e) =>{ 
    if(e.target.scrollTop <20 && visible){
        setVisible(false);
    } 
  }

  
  const show = (item) =>{ 
    setItem(item);
    setVisible(true);

  }
  const hide = (e) =>{ 
    setItem({});
    setVisible(false);
  }
  const parentClicked = (e) =>{ 
    if(e.target.className.indexOf('product-detail-wrapper')<=-1){
      return;
    }
    hide(); 
    e.persist(); 
    e.stopPropagation(); 
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault()  ;
  }
  const wrapperRef = (ref) =>{ 
    if(!ref)
    {
      return;
    }
    
    ref.scrollTop = 50;
    setTimeout(() => {
      ref.scrollTo({
        top: (window.screen.availHeight / 100 * 60),
        left: 0,
        behavior: "smooth",
      });
    }, 50);


  }
 
  const purchase = () =>{
    if(navigator.userAgent.toLowerCase().indexOf('wechat')>-1){
      window.__sna.pay({ 
        products:[{key:item.id, name:item.name, desc:item.desc, qty:1, type:'product', price:item.price * 1000, url:window.location.href }]
      });
    }
    else if(navigator.userAgent.toLowerCase().indexOf('alipay')>-1){
      window.__sna.pay({ 
        products:[{key:item.id, name:item.name, desc:item.desc, qty:1, type:'product', price:item.price * 1000, url:window.location.href }]
      });
    } 
    else{
      //show payment options
      window.__sna.checkout({ 
        products:[{key:item.id, name:item.name, desc:item.desc, qty:1, type:'product', price:item.price * 1000, url:window.location.href }]
      });
    }
  }

  if(!visible){
    return <></>
  }

  return (
    <div className="product-detail-wrapper" onScroll={scorolled} ref={wrapperRef} onClick={parentClicked}>
        <div className="detail-head">
          <div className="detail-head-text">{item.name}</div>
          <div className="detail-head-close">
            <Icon name="cancel" clickable={true}  onClick={hide}  />
          </div>
        </div>
        <div className="product-detail">
            <div className="product-image" style={{backgroundImage:'url('+item.image+')'}}></div>
            <div className="product-name">{item.name}</div>
            <div className="product-desc">{item.desc}</div>
            {item.oldPrice && <div className="product-old-price">RMB {item.oldPrice}.00</div>}
            <div className="product-price">RMB {item.price}.00</div> 
            {/* <div className="product-buy-together clickable">Buy together</div>  */}
            <div className="product-direct-buy clickable" onClick={purchase}>Purchase</div> 
            <div className="product-content">
            鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础 
            <p>
            鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础  
              </p>   <p>
            鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础  
              </p>   <p>
            鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认,乃是世界自由、正义与和平的基础  
              </p> 
            </div> 
        </div>
    </div>
  );
});

export default ProductDetail; 