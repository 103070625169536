 
import './Icon.css';

function Icon(props) {
  const clicked = () =>{
    
  }
  return (
    <div className={"app-icon material-symbols-sharp " + (props.clickable?' clickable ':' ')} onClick={props.onClick || clicked} >{props.name}</div>
  );
} 
export default Icon;
