 
import { forwardRef, useImperativeHandle, useRef } from 'react';
import Message from './Message';
import './MessageList.css';

const MessageList = forwardRef((props,ref) => {
  
  useImperativeHandle(ref, () => ({ 
    scrollToEnd
  }));
    
  const listRef = useRef();

  const scrollToEnd = (item) =>{  
     if(listRef && listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
     }

  } 


  return (
    <div className="message-list" ref={listRef}>
      {props.items.map(i=><Message key={i.id} item={i} /> )} 
    </div>
  );
});

export default MessageList;
